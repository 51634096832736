import toDate from "date-fns-tz/toDate";
import _ from "lodash";
import { createSelector } from "reselect";
import { ExamStatus } from "../../services/nav-api/student-details/types";
import { RdxStudentDetails } from "../reducers/student-details";
import { RdxStoreState } from "../types/state";
import { RdxFetchStatus } from "./../types/status";
import { getStudentEnrollments, StudentEnrollment } from "./student-enrollments";

export type Exam = {
  examDate?: Date;
  examGrade?: number;
  examStatus?: ExamStatus;
};

export type StudentExam = {
  course: string;
  purchaseDate?: Date | undefined;
  expirationDate?: Date | undefined;
  isUnlimited?: boolean;
  exams: Exam[];
};

const orderWeightsByAltId: Record<string, number> = {
  F: 1,
  FAR: 1,
  A: 2,
  AUD: 2,
  R: 3,
  REG: 3,
  B: 4,
  BEC: 4
};

export const getStudentExams: (state: RdxStoreState) => { exams?: StudentExam[]; loading?: boolean } = createSelector(
  getStudentEnrollments,
  (state: RdxStoreState) => state.studentDetails,
  ({ enrollments }, { curStudentId, details, status }: RdxStudentDetails) => {
    if (!curStudentId) {
      return { loading: true };
    }

    if (!details || status === RdxFetchStatus.LOADING) {
      return { loading: true };
    }

    const makeExams = (enrollment: StudentEnrollment) => {
      const section = details.examInfo?.sections.filter(section => section.sectionAltId === enrollment.sectionAltId)[0];

      if (!section) {
        return [];
      }

      return section.attempts.map(attempt => ({
        examDate: attempt.examDate ? toDate(attempt.examDate) : undefined,
        examGrade: attempt.examGrade,
        examStatus: attempt.examStatus
      }));
    };

    const exams = _.chain(enrollments)
      .map(e => ({ ...e, exams: makeExams(e) }))
      .orderBy(e => orderWeightsByAltId[_.upperCase(e.sectionAltId)])
      .value();

    return { exams: (exams || []).flat() };
  }
);
