import { ColumnProps } from "antd/lib/table";
import memoize from "lodash/memoize";
import React from "react";
import { FollowBtn } from "../../../components/shared/follow-student-icon";
import {
  composeHandlers,
  EMPTY_CELL_SYMBOL,
  handleCellText,
  handleDateCell,
  handleDateDistanceFromNowInDays,
  handleExpirationDate,
  handleStudyPlanCell,
  renderWithMaxWidth
} from "../../../components/shared/table/table-cell-value";
import { getProductConfig } from "../../../product-config";
import { StudentRosterDetails } from "../../../services/nav-api/student-roster/types";
import { VisibilityBadge } from "../shared/status-badge";

type SortProps = { key: string; order: "ascend" | "descend" };

export const createColumns = memoize(
  (
    sort?: SortProps,
    showVisibility = false,
    showTenants = false,
    onFollowClick?: (id: number, followed: boolean) => void
  ): ColumnProps<{}>[] =>
    [
      onFollowClick && {
        title: "",
        dataIndex: "followed",
        width: 32,
        render: function FollowCol(followed = false, item: StudentRosterDetails) {
          return (
            <FollowBtn
              active={followed}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                onFollowClick(item.id, !item.followed);
              }}
            />
          );
        }
      },
      {
        title: "Last Name",
        dataIndex: "lastName",
        render: composeHandlers(handleCellText, renderWithMaxWidth(150)),
        sorter: true,
        sortOrder: sort && sort.key === "lastName" ? sort.order : undefined
      },
      {
        title: "First Name",
        dataIndex: "firstName",
        render: composeHandlers(handleCellText, renderWithMaxWidth(150)),
        sorter: true,
        sortOrder: sort && sort.key === "firstName" ? sort.order : undefined
      },
      {
        title: "Email",
        dataIndex: "username",
        render: composeHandlers(handleCellText, renderWithMaxWidth(200)),
        width: 200,
        sorter: true,
        sortOrder: sort && sort.key === "email" ? sort.order : undefined
      },
      {
        title: "Enrolled",
        dataIndex: "licensePurchaseDate",
        render: handleDateCell
      },
      getProductConfig().hasStudyPlan
        ? {
            title: "Study Plan",
            dataIndex: "hasStudyPlan",
            render: handleStudyPlanCell
          }
        : (undefined as any),
      {
        title: "Last Activity",
        dataIndex: "lastActivity",
        render: function LastActivity(date: string) {
          return handleDateDistanceFromNowInDays(date, true);
        }
      },
      {
        title: "Expiration Date",
        dataIndex: "licenseExpirationDate",
        render: handleExpirationDate
      },
      showTenants
        ? {
            title: "Tenants",
            dataIndex: "tenants",
            render: function StatusCol(tenants: StudentRosterDetails["tenants"]) {
              return tenants?.map(tenant => tenant.name).join(", ") || EMPTY_CELL_SYMBOL;
            }
          }
        : (undefined as any),
      showVisibility
        ? {
            title: "Visibility",
            dataIndex: "isExcluded",
            render: function StatusCol(isExcluded = false) {
              return <VisibilityBadge disabled={isExcluded} />;
            }
          }
        : (undefined as any)
    ].filter(Boolean),
  (sort?: SortProps, showVisibility = false, showTenants = false) => {
    if (!sort) {
      return "" + showVisibility + showTenants;
    }
    return sort.key + sort.order + showVisibility + showTenants;
  }
);
